:root {
}

html {
	/* --bs-primary: gray; */
	--bg-1: #fff;
	--bg-2: #f4f4f4;
	--bg-3: #ddd;
	--bg-4: #ccc;
	--bg-back: var(--bg-2);
	--bg-front: var(--bg-1);
	--bg-dropdown: var(--bg-1);
	--txt-1: #000;
	--txt-2: #222;
	--txt-3: #333;
	--txt-4: #444;
	--txt-5: #555;
	--txt-6: #666;
	--form-control-bg: #fff;
	--form-control-bg-focus: #eee;
	--lighten: rgba(255, 255, 255, 0.15);
	--darken: rgba(0, 0, 0, 0.07);
	--darken-1: rgba(0, 0, 0, 0.07);
	--darken-2: rgba(0, 0, 0, 0.15);
	--selection: #ff1b1b;
	scroll-behavior: auto !important;

	--mray-0: rgba(127, 127, 127, 1);
	--mray-1: rgba(127, 127, 127, 0.8);
	--mray-2: rgba(127, 127, 127, 0.6);
	--mray-3: rgba(127, 127, 127, 0.4);
	--mray-4: rgba(127, 127, 127, 0.2);
	--mray-5: rgba(127, 127, 127, 0.1);

	.table {
		--bs-table-hover-bg: var(--mray-3);
	}
}

html.theme-dark {
	--bg-1: #111416;
	/* #060d21; */
	--bg-2: #212426;
	/* #101b3c */
	;
	--bg-3: #2d3032;
	--bg-4: #3b3b3b;
	--bg-back: var(--bg-1);
	--bg-front: var(--bg-2);
	--bg-dropdown: var(--bg-3);
	--txt-1: #fff;
	--txt-2: #eee;
	--txt-3: #ddd;
	--txt-4: #ccc;
	--txt-5: #bbb;
	--txt-6: #aaa;
	--form-control-bg: var(--darken);
	--form-control-bg-focus: var(--bg-1);
	--lighten: rgba(255, 255, 255, 0.05);
	--darken: rgba(0, 0, 0, 0.2);
	--selection: #ff8400;

	--mray-0: rgba(127, 127, 127, 1);
	--mray-1: rgba(127, 127, 127, 0.8);
	--mray-2: rgba(127, 127, 127, 0.6);
	--mray-3: rgba(127, 127, 127, 0.4);
	--mray-4: rgba(127, 127, 127, 0.2);
	--mray-5: rgba(127, 127, 127, 0.1);

	.table {
		--bs-table-striped-bg: var(--lighten);
		--bs-table-striped-color: var(--txt-2);
	}


}

@media print {
	html {
		--bg-1: #fff;
		--bg-2: #fff;
		--bg-3: #fff;
		--bg-4: #fff;
		--txt-1: #000;
		--txt-2: #000;
		--txt-3: #000;
		--txt-4: #000;
		--txt-5: #000;
		--txt-6: #000;
	}
}