@import './variables.scss';
@import './modal.scss';
@import './tip.scss';


.absolute-center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate3d(-50%, -50%, 0);
}
