#modal-wrapper {
	.modal {
		.modal-overlay {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			background: rgba(0, 0, 0, 0.5);
			// transition: background 0.25s ease-out;
			// &.shown {
				html.theme-dark & {
					background: rgba(0, 0, 0, 0.8);
				}
				html.theme-light & {
					background: rgba(0, 0, 0, 0.6);
				}
			// }
		}
		.modal-dialog {
			&.modal-xxs {
				max-width: 340px;
			}
			&.modal-xs {
				max-width: 480px;
			}
			&.modal-sm {
				max-width: 767px;
			}
			&.modal-md {
				max-width: 998px;
			}
			&.modal-lg {
				max-width: 1200px;
			}
			
			.modal-content {
				// overflow-y: hidden;
				// overflow-x: visible;
				max-width: 100%;
				background-color: var(--bg-front);
				color: var(--txt-2);
				// transition: all 0.25s cubic-bezier(0, 0.69, 0.36, 1.19);
				// transform: scale(1.2);
				// opacity: 0;
				// &.shown {
				// 	transform: scale(1);
				// 	opacity: 1;
				// }
				.modal-header {
					border-color:var(--mray-3);
					min-height: 47px;
					padding: 0.5rem 0;
					html.os-no-win & {
						flex-direction: row-reverse;
						justify-content: flex-end;
					}
					.modal-title {
						padding: 0rem 1rem;
						font-size: 1rem;
						html.os-no-win & {
							padding-left: 0;
						}
					}
					.btn-close {
						padding: 0.5rem 1rem;
						margin: 0 2px;
					}
				}
				.modal-body {
					min-height: 70px;
				}
			}
			.modal-footer {
				border-color:var(--mray-3);
				justify-content: space-between;
				html.os-no-win & {
					flex-direction: row-reverse;
				}
				.d-flex {
					gap: 7px;
					html.os-no-win & {
						flex-direction: row-reverse;
					}
				}
			}
		}
	}
}
html.theme-dark #modal-wrapper .modal .modal-overlay {
	// background: rgba(0, 0, 0, 0.8);
}
#modal-wrapper .spinner {
	// top: 50%;
    // margin-top: -9px;
}
