.tip {
	overflow: visible !important;

	&:hover {
		&::after {
			content: attr(data-title);
			position: absolute;
			left: 50%;
			top: 100%;
			transform: translateX(-50%);
			z-index: 99;
			max-width: 300px;
			line-height: 1.1rem;
			font-size: 0.65rem !important;
			padding: 2px 9px 2px;
			margin-top: 5px;
			color: var(--bg-1) !important;
			white-space: nowrap;
			background: var(--txt-1);
			-webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
			-moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
			box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
		}

		&::before {
			content: ' ';
			position: absolute;
			left: 50%;
			top: 100%;
			width: 0;
			margin-top: -7px;
			height: 0;
			margin-left: -6px;
			border: 6px solid transparent;
			border-bottom-color: var(--txt-1);
		}
	}
}
